<template lang="pug">
head-meta(title-page="Контакты интернет магазина ChiedoHome, все способы связи", description="Контакты ChiedoHome - телефоны, e-mail, мессенджеры, карта с адресами склада, офиса и представителей")
contacts
</template>

<script>
import Contacts from '../components/contacts/Contacts'
import HeadMeta from '../components/_layout/HeadMeta'

export default {
  name: 'ContactsPage',
  components: { HeadMeta, Contacts }
}
</script>
