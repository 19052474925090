<template lang="pug">
article.contacts
  .contacts__cont.cont
    h1.contacts__caption.caption Контакты
    p.contacts__text.text-big Свяжитесь с нами любым удобным способом
    ul.contacts__list
      li.contacts__item
        span.contacts__note Телефон
        a.contacts__phone.headline.link_black-orange(:href="phone.link") {{phone.mask}}
      li.contacts__item
        span.contacts__note Отдел продаж
        a.contacts__mail.headline.link_black-orange(:href="mail.link") {{mail.mask}}
      li.contacts__item
        span.contacts__social-note Соц.сети и мессенджеры
        .contacts__social-list
          a.contacts__social-item(
              v-for="social in socials"
              :key="social.mod"
              :href="social.link"
              :class="`contacts__social-item_${social.mod}`"
              :title="social.name"
            )
    h2.contacts__title.title Адреса
    ul.contacts__address-list
      li.contacts__address-item(v-for="(item, i) in addresses" :key="i")
        span.contacts__address-note {{item.name}}
        button.contacts__address.link_black-orange.text-big(type="button", @click="scrollToMap") {{item.address}}
        span.contacts__address-worktime.text(v-for="(time, i) in item.worktime" :key="i") {{time}}
    the-requisites(:type="'block'")
    yandex-map(id="contacts__map", ymap-class="contacts__map", :coords="[57.380755, 43.552709]" :zoom="zoom" :controls="controls" :behaviors="behaviors")
      ymap-marker(v-for="(shop, i) in shopsOnMap", :key="i", :marker-id="i", :coords="shop.coords", :hint-content="shop.address")
</template>

<script>
import '@/assets/styles/components/contacts.sass'

import { yandexMap, ymapMarker } from 'vue-yandex-maps'
import TheRequisites from '@/components/requisites/TheRequisites'
import socials from '../../assets/scripts/socials'
import { worktime, mail, phone, addresses } from '../../assets/scripts/variables'

export default {
  name: 'Contacts',
  components: { yandexMap, ymapMarker, TheRequisites },
  data () {
    return {
      socials,
      worktime,
      mail,
      phone,
      addresses,
      controls: ['zoomControl'],
      behaviors: ['drag', 'multiTouch', 'dblClickZoom', 'scrollZoom']
    }
  },
  computed: {
    zoom () {
      if (window.innerWidth > 1700) {
        return 6
      } else if (window.innerWidth > 1240) {
        return 5
      } else {
        return 4
      }
    },
    shopsOnMap () {
      const array = []
      for (let i = 0; i < addresses.length; i++) {
        const { coords, address } = addresses[i]
        array.push({ coords, address })
      }
      return array
    }
  },
  methods: {
    scrollToMap () {
      window.scrollTo({
        top: document.getElementById('contacts__map').getBoundingClientRect().top + window.pageYOffset,
        behavior: 'smooth'
      })
    }
  }
}
</script>
