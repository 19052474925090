<template lang="pug">
.requisites__content.content
  p Общество с ограниченной ответственностью «БАРИОН»
  p Юридический адрес: 610002, Кировская область, г. Киров, ул. Казанская, д. 115, помещ. 304
  p ИНН: 4345302465
  p ОГРН: 1114345009261
  p КПП: 434501001
  p ОКПО: 67570959
  p Расчетный счет: 40702810300350130035
  p Банк: ПАО "Норвик Банк", г. Киров
  p БИК: 043304728
  p Кор.счет: 30101810300000000728
</template>

<script>
export default {
  name: 'TheRequisitesContent'
}
</script>
