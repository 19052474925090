<template lang="pug">
article.requisites
  template(v-if="type === 'page'")
    .requisites__cont.cont
      h1.requisites__caption.caption Реквизиты
      the-requisites-content
  template(v-if="type === 'block'")
    h2.requisites__title.title Реквизиты
    the-requisites-content
</template>

<script>
import '@/assets/styles/components/requisites.sass'
import TheRequisitesContent from '../requisites/TheRequisitesContent'

export default {
  name: 'TheRequisites',
  components: { TheRequisitesContent },
  props: {
    type: {
      type: String,
      default: 'page'
    }
  }
}
</script>
